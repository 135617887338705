import React from 'react';
import {PolIcon} from "components/PolIcon/policon";

export default function DebugBarRequest({request}) {
    return (
        <>
            <div className="debug-bar__item">
                <span>{request.method}</span>
            </div>

            <div className="debug-bar__item uri">
                <PolIcon icon="cogs"/>
                <a href={request.uri}
                   target="_blank"
                   rel="noopener noreferrer"
                   title={request.uri}>
                    {request.uri.split('/api')[1]}
                </a>

                <div className="debug-bar__item-hover">
                    <p className="debug-bar__item-hover-row">
                        <span>route</span> <b>{request.route}</b>
                    </p>
                </div>
            </div>
        </>
    )
}
