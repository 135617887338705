import useToggle from "v4/hooks/useToggle";
import {useEffect} from "react";

export default function useDebugBarTokenList({setSelectedToken}) {
    const [isOpen, toggleIsOpen] = useToggle();

    const handleSelect = token => () => setSelectedToken(token);

    // If click outside the dropdown, close it
    useEffect(() => {
        const handleClick = e => {
            if (!e.target.closest('.debug-bar__token-item__select')) {
                toggleIsOpen(false);
            }
        }

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        }
    }, [isOpen, toggleIsOpen]);

    return {
        handleSelect,
        isOpen,
        toggleIsOpen,
    }
}
