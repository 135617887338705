import React from 'react';
import InlineLoader from "v4/components/ui/InlineLoader/InlineLoader";
import DebugBarPerformance
    from "v4/features/front/debugBar/components/DebugBar/components/DebugBarPerformance/DebugBarPerformance";
import DebugBarRequest from "v4/features/front/debugBar/components/DebugBar/components/DebugBarRequest/DebugBarRequest";
import DebugBarResponse
    from "v4/features/front/debugBar/components/DebugBar/components/DebugBarResponse/DebugBarResponse";
import DebugBarSecurity
    from "v4/features/front/debugBar/components/DebugBar/components/DebugBarSecurity/DebugBarSecurity";
import DebugBarTokenList
    from "v4/features/front/debugBar/components/DebugBar/components/DebugBarTokenList/DebugBarTokenList";

export default function DebugBarRow({tokensData, selectedToken, setSelectedToken}) {
    const tokenData = tokensData[selectedToken];
    const isTokenLoading = !tokenData;

    return (
        <div className="debug-bar__row">
            <div className="debug-bar__item">
                <DebugBarTokenList tokenData={tokenData}
                                   tokensData={tokensData}
                                   isTokenLoading={isTokenLoading}
                                   selectedToken={selectedToken}
                                   setSelectedToken={setSelectedToken}/>
            </div>
            {isTokenLoading
                ? <InlineLoader text="" className="debug-bar__loader"/>
                : (
                    <>
                        <DebugBarResponse response={tokenData.response}/>
                        <DebugBarRequest request={tokenData.request}/>
                        <DebugBarPerformance performance={tokenData.performance}
                                             debugUrl={tokenData.debugUrl}/>
                        <DebugBarSecurity security={tokenData.security}/>
                    </>
                )
            }
        </div>
    )
}
