import useToggle from "v4/hooks/useToggle";
import {useDebugBarContext} from "v4/features/front/debugBar/contexts/DebugBarContext";
import {useEffect, useState} from "react";
import {generateUrl, MIDDLEWARE_URL} from "v4/services/api.service";
import {DEBUG_BAR} from "v4/data/apiRoutes";

export default function useDebugBar() {
    const {tokens} = useDebugBarContext();
    const sortedTokens = Object.entries(tokens).sort(([, a], [, b]) => a - b);
    const [isOpen, toggleOpen] = useToggle(true);
    const [tokensPromises, setTokensPromises] = useState({});
    const [tokensData, setTokensData] = useState({});
    const [selectedToken, setSelectedToken] = useState(null);

    useEffect(() => {
        if (sortedTokens.length === 0) {
            return;
        }
        const newTokensPromises = {};
        sortedTokens.forEach(([token]) => {
            if (tokensPromises[token]) {
                newTokensPromises[token] = tokensPromises[token];
                return;
            }

            newTokensPromises[token] = fetch(`${MIDDLEWARE_URL}${generateUrl(DEBUG_BAR, {token}, false)}`)
                .then(response => {
                    if (!response.ok) {
                        return Promise.reject(response);
                    }

                    return response.json();
                })
                .then(data => {
                    setTokensData(prevTokensData => ({
                        ...prevTokensData,
                        [token]: data,
                    }));
                })
                .catch(async errorResponse => {
                    const error = await errorResponse.json();
                    console.error(`Fetch for token ${token} couldn't be completed:`, error);
                });

            setTokensPromises(newTokensPromises);
        });
    }, [sortedTokens, tokensPromises]);

    return {
        isOpen,
        toggleOpen,
        tokensData,
        selectedToken: selectedToken ?? sortedTokens[sortedTokens.length - 1]?.[0],
        setSelectedToken,
    }
}
