import React from 'react';
import {PolIcon} from "components/PolIcon/policon";
import useDebugBarTokenList
    from "v4/features/front/debugBar/components/DebugBar/components/DebugBarTokenList/hooks/useDebugBarTokenList";
import {useTranslation} from "react-i18next";

export default function DebugBarTokenList({tokenData, tokensData, selectedToken, setSelectedToken, isTokenLoading}) {
    const {t} = useTranslation();
    const {handleSelect, isOpen, toggleIsOpen} = useDebugBarTokenList({setSelectedToken});

    return (
        <div className="debug-bar__token-item">
            {isTokenLoading
                ? <span>{selectedToken}</span>
                : (
                    <a href={tokenData.debugUrl}
                       rel="noopener noreferrer"
                       target="_blank">
                        {selectedToken}
                    </a>
                )
            }
            <div className={`debug-bar__token-item__select ${isOpen ? 'open' : ''}`}
                 onClick={toggleIsOpen}>
                <PolIcon icon="chevron-down"
                         className="debug-bar__token-item__select__icon"/>

                <div className="debug-bar__token-item__select__list-container">
                    <ul className="debug-bar__token-item__select__list">
                        {Object.keys(tokensData).map(token => {
                            const isStatusOk = tokensData[token].response.status_code >= 200 && tokensData[token].response.status_code < 300;
                            const status = isStatusOk ? 'success' : 'error';
                            return (
                                <li key={token}
                                    className={`debug-bar__token-item__select__list__item  ${selectedToken === token ? 'selected' : ''}`}
                                    onClick={handleSelect(token)}>
                                    <span>{token}</span>
                                    <span className={`pol-badge pol-badge-${status}`}>{tokensData[token].response.status_code}</span>
                                    <span className={`pol-badge`}>{tokensData[token].performance.total}ms</span>
                                    <span>{tokensData[token].request.method}</span>
                                    <span title={tokensData[token].request.uri}>
                                        {(tokensData[token].request.uri).split('/api')[1]}
                                    </span>
                                </li>
                            )
                        })}
                        <li className="debug-bar__token-item__select__list__item latest"
                            onClick={handleSelect(null)}>
                            {t('latest')}
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    )
}
