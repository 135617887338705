import React from 'react';
import {PolIcon} from "components/PolIcon/policon";

export default function DebugBarSecurity({security}) {
    return (
        <div className="debug-bar__item">
            <PolIcon icon="user"/>
            <span>{security.user}</span>

            <div className="debug-bar__item-hover">
                <p className="debug-bar__item-hover-row align-start">
                    <span>roles</span>
                    <span className="debug-bar__item-hover-row__list-value">
                        {security.roles.map((role, index) => (
                            <span key={index}>{role}</span>
                        ))}
                    </span>
                </p>
                <hr/>
                <p className="debug-bar__item-hover-row">
                    <span>is_authenticated</span> <b>{security.is_authenticated ? 'true' : 'false'}</b>
                </p>
                <p className="debug-bar__item-hover-row">
                    <span>is_impersonated</span> <b>{security.is_impersonated ? 'true' : 'false'}</b>
                </p>
            </div>
        </div>
    )
}
