import React from 'react';
import './DebugBar.scss';
import {createPortal} from "react-dom";
import useDebugBar from "v4/features/front/debugBar/components/DebugBar/hooks/useDebugBar";
import {PolIcon} from "components/PolIcon/policon";
import DebugBarRow from "v4/features/front/debugBar/components/DebugBar/components/DebugBarRow/DebugBarRow";

export default function DebugBar() {
    const {isOpen, toggleOpen, tokensData, selectedToken, setSelectedToken} = useDebugBar();

    if (Object.keys(tokensData).length === 0) return null;

    return createPortal(
        <div className={`debug-bar ${isOpen ? 'open' : ''}`}>
            <DebugBarRow tokensData={tokensData}
                         selectedToken={selectedToken}
                         setSelectedToken={setSelectedToken}/>
            <button className="debug-bar__toggle"
                    title="Debug bar"
                    onClick={toggleOpen}>
                <PolIcon icon="arrow-right"/>
            </button>
        </div>
        , document.body);
}
