import ErrorBoundary from "components/Error/errorBoundary";
import {PolIcon} from "components/PolIcon/policon";
import React, {useEffect} from "react";
import {createPortal} from "react-dom";
import ReactTooltip from "react-tooltip";
import "v4/features/front/export/components/ListingExport/ListingExport.scss"
import ViewOrdersList from "v4/features/front/listingViewOrdersManagement/components/ViewOrdersList/ViewOrdersList";
import useFetch from "v4/hooks/useFetch";
import useNotification from "v4/hooks/useNotification";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useToggle from "v4/hooks/useToggle";
import {generateUrl} from "v4/services/api.service";
import {EXPORT_ENTITY} from "v4/data/apiRoutes";

export default function ListingExport({selectedItems, entity}) {
    const {addErrorNotification, addSuccessNotification} = useNotification()
    const {t} = usePolTranslation()
    const [{
        data,
        errorData,
        isLoading,
        isFinished,
        isError,
    }, fetchExport] = useFetch();
    const [isOpen, toggleOpen] = useToggle();

    const handleExport = (fields) => {
        fetchExport({
            url: generateUrl(EXPORT_ENTITY, {entity}),
            config: {
                method: 'POST',
                body: JSON.stringify({
                    ids: selectedItems,
                    fields: fields
                })
            }
        })
    }

    useEffect(() => {
        if (data && isFinished && !isError) {
            addSuccessNotification(t(data?.message ?? 'success'));
        }

        if (isFinished && isError) {
            addErrorNotification(t(errorData?.message ?? 'error',{entity}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isFinished, isError])

    const tableContainer = document.querySelector('#root');

    useEffect(() => {
        const bodyClassList = document.querySelector('body').classList;
        bodyClassList.toggle('overflowh', isOpen);
        return () => bodyClassList.remove('overflowh');
    }, [isOpen]);

    return (
        <ErrorBoundary>
            <li className={`listing-header__action ${selectedItems?.length > 0 ? '' : 'disabled'}`}
                onClick={selectedItems?.length > 0 ? toggleOpen : undefined}
                data-for="export"
                data-tip={selectedItems?.length > 0 ? t('export_data') : t('empty_fields_or_ids')}>
                <PolIcon icon="dwl-csv" className="action__picto"/>
            </li>
            <ReactTooltip id="export" effect="solid"/>
            {tableContainer &&
                createPortal(
                    <div className={`export-view-orders-list${isOpen ? ' open' : ''}`}>
                        <div className={`export-view-orders-list-container`}>
                            <div className="action-view-orders-list__heading">
                                <p>{t('select_columns_to_export')}</p>
                                <span className="action-view-orders-list__close" onClick={toggleOpen}>
                                    <PolIcon icon={'times-circle'}/>
                                </span>
                            </div>
                            {isOpen &&
                                <ViewOrdersList onSubmit={handleExport} onCancel={toggleOpen}
                                                entity={entity} submitLabel={t('export')}
                                                isBlocked={isLoading} isCheckBoxMode={true}/>
                            }
                        </div>
                    </div>
                    , tableContainer
                )
            }
        </ErrorBoundary>
    )
}
