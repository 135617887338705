import React from 'react';

export default function DebugBarResponse({response}) {
    const status = response.status_code?.toString()?.startsWith('2') ? 'success' : 'error';

    return (
        <div className="debug-bar__item">
            <span className={`pol-badge pol-badge-${status}`}
                  title="Response">
                {response.status_code}
            </span>

            <div className="debug-bar__item-hover">
                <p className="debug-bar__item-hover-row">
                    <span>content_type</span> <b>{response.content_type}</b>
                </p>
            </div>
        </div>
    )
}
